<template>
  <div class="g-bg-color--sky-light">
    <div class="container g-padding-y-80--xs g-padding-y-125--sm">
      <div class="g-text-center--xs g-margin-b-80--xs">
        <p
          class="text-uppercase g-font-size-14--xs g-font-weight--700 g-color--primary g-letter-spacing--2 g-margin-b-25--xs"
        >
          {{ $t("Feedback.Feedback") }}
        </p>
        <h2 class="g-font-size-32--xs g-font-size-36--md">
          {{ $t("Feedback.Note") }}
        </h2>
      </div>
      <form>
        <div class="row g-margin-b-40--xs">
          <div class="col-sm-6 g-margin-b-20--xs g-margin-b-0--md">
            <div class="g-margin-b-20--xs">
              <input
                type="text"
                class="form-control s-form-v2__input g-radius--50"
                :placeholder="$t('Feedback.Name')"
              />
            </div>
            <div class="g-margin-b-20--xs">
              <input
                type="email"
                class="form-control s-form-v2__input g-radius--50"
                :placeholder="$t('Feedback.Email')"
              />
            </div>
            <input
              type="text"
              class="form-control s-form-v2__input g-radius--50"
              :placeholder="$t('Feedback.Phone')"
            />
          </div>
          <div class="col-sm-6">
            <textarea
              class="form-control s-form-v2__input g-radius--10 g-padding-y-20--xs"
              rows="8"
              :placeholder="$t('Feedback.Message')"
            ></textarea>
          </div>
        </div>
        <div class="g-text-center--xs">
          <button
            type="submit"
            class="text-uppercase s-btn s-btn--md s-btn--primary-bg g-radius--50 g-padding-x-80--xs"
          >
            {{ $t("Feedback.Submit") }}
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
