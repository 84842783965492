export default {
  "Sliders": {
    "Slider1": {
      "Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşletmenizin Karbon Ayak İzi <br/> Benafri'ye Emanet!"])}
    },
    "Slider2": {
      "Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turizme Özel Hazırlanan Altyapısı İle <br/> Rahat Edeceksiniz!"])}
    },
    "LearnMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha Fazla Bilgi"])}
  },
  "NavMenu": {
    "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ana Sayfa"])},
    "About": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hakkımızda"])},
    "Team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekibimiz"])},
    "Events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkinlikler"])},
    "Faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sık Sorulan Sorular"])},
    "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İletişim"])},
    "AppLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giriş"])}
  },
  "Footer": {
    "Nav": {
      "Home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ana Sayfa"])},
      "Team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekibimiz"])},
      "Events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkinlikler"])},
      "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İletişim"])}
    },
    "SocialMedia": {
      "Facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facebook"])},
      "Instagram": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instagram"])},
      "Linkedin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Linkedin"])}
    },
    "CompanyInfo": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benafri (Be Nature Friendly) "])},
      "Remark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Sürdürülebilir Turizm için hizmet ediyoruz."])}
    },
    "CopyRight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyrights © 2022 All Rights Reserved by Notilva Yazılım. Sitenin Tüm Hakları saklıdır."])}
  },
  "Feature": {
    "Welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benafri'ye Hoş Geldiniz"])},
    "Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neler Yapıyoruz?"])},
    "Detail1": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISO 14064-1"])},
      "Remark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Iso Standartlarında Karbon Emisyonlarınızı Hesaplıyor ve Raporluyoruz."])}
    },
    "Detail2": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GHG Protokolleri"])},
      "Remark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Green House Gas Protokollerine Uygun Hesaplamalar."])}
    },
    "Detail3": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Birden Fazla Şirket"])},
      "Remark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm Şirketlerinizi Tek Uygulamadan Yönetebilirsiniz."])}
    },
    "Detail4": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konsolide Raporlama"])},
      "Remark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tüm Şirketlerinizin Raporlarını Konsolide Olarak Alabilirsiniz."])}
    },
    "Detail5": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operasyonel Veri"])},
      "Remark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operasyonunuza Özel Verilere Göre Karbon Tüketimlerinizi Görebilirsiniz."])}
    },
    "Detail6": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Widget"])},
      "Remark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sisteminizden Oluşturulacak Widgetlar Aracılığı ile Karbon Miktarınızı Platform Bağımsız Paylaşabilirsiniz."])}
    }
  },
  "Clients": {
    "Client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İş Ortakları ve Referanslarımız"])},
    "ClientMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İş Ortaklarımız"])},
    "ReferanceMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referanslarımız"])}
  },
  "Paralax": {
    "Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulamamızı kullandığınız her ay İçin 2 Adet fidan dikiyoruz!"])}
  },
  "Feedback": {
    "Feedback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İletişim"])},
    "Note": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bizimle İletişime Geçin"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Adınız"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Mail Adresiniz"])},
    "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Telefon Numaranız"])},
    "Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Mesajınız"])},
    "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönder"])}
  },
  "Process": {
    "Process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İşlemler"])},
    "HowitWork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nasıl Çalışıyoruz"])},
    "Detail1": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KAYNAK TESPİTİ"])},
      "Remark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hesaplamalar için en önemli adım kaynakların doğru ve eksiksiz tespit edilmesidir. Tecrübeli danışmanlarımız bu süreci sizinle beraber tamamlar."])}
    },
    "Detail2": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VERİ TOPLAMA"])},
      "Remark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dijital ve pratik çözümlerle emisyon kaynaklarına ait veriler kolayca toplanır. "])}
    },
    "Detail3": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HESAPLAMA RAPORLAMA"])},
      "Remark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İnsani hataların önüne geçerek emisyon hesaplarınız doğru bir şekilde hesaplanır. ISO 14064 Standartlarına göre raporlama. Birden fazla şirketin konsolide raporlaması."])}
    },
    "Detail4": {
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ÖZELLEŞTİRME"])},
      "Remark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şirketinizin operasyon akışına göre dijital özelleştirme kabiliyeti."])}
    }
  },
  "Contact": {
    "Contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bize Ulaşın"])},
    "Remark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İletişime Geçin"])},
    "CompanyEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Posta"])},
    "CompanyAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adres"])},
    "CompanyPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
    "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Adınız"])},
    "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Mail Adresiniz"])},
    "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Telefon Numaranız"])},
    "Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Mesajınız"])},
    "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gönder"])}
  },
  "Testimonials": {
    "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Görüşler"])},
    "Detail1": {
      "Remark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "Person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prof.Dr. Gökhan CİVELEKOĞLU <br> Noventech Çevre Teknolojileri Ar-Ge Danışmanlık"])}
    },
    "Detail2": {
      "Remark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Turizm konusunda engin tecrübelere sahip olan ve konusunda uzman kişiler tarafından yönetilen bir organizasyon. Turizmde sürdürülebilirlik konusunda vazgeçilmez olacaklar."])},
      "Person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cenk TİMURLENK Finansal Danışman ve Veri Madencisi"])}
    }
  },
  "Team": {
    "Experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["21 Yıllık Tecrübe"])},
    "Message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekibimiz"])},
    "Detail1": {
      "Staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adil TOLA"])},
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yönetim Kurulu Başkanı"])}
    },
    "Detail2": {
      "Staff": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sinan Baran BAYAR"])},
      "Title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["İdari İşler Müdürü"])}
    }
  },
  "Events": {
    "Upcoming": {
      "EventName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2.Sürdürülebilir <br> Turizm Zirvesi"])},
      "Remark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tusder Turizmde Sürdürülebilirlik Derneği. Alanında uzman isimleri bir araya getirerek, sürdürülebilir turizm ile ilgili en kapsamlı zirveyi hazırlamıştır."])},
      "Date": {
        "Day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2-3"])},
        "Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aralık"])},
        "Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022"])}
      }
    },
    "JoinEvent": {
      "Join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etkinliğe Katıl"])},
      "Name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Adınız"])},
      "Email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* E-Posta"])},
      "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Telefon Numaranız"])},
      "Submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şimdi Katıl"])}
    },
    "Event1": {
      "EventName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2.Sürdürülebilir Turizm Zirvesi"])},
      "Remark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tusder Turizmde Sürdürülebilirlik Derneği. Alanında uzman isimleri bir araya getirerek, sürdürülebilir turizm ile ilgili en kapsamlı zirveyi hazırlamıştır."])},
      "FindOutMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daha Fazla Bilgi"])},
      "EventSponsor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Karbon Ayak İzİ Sponsoru"])},
      "Date": {
        "Day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2-3"])},
        "Month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aralık"])},
        "Year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2022"])}
      }
    }
  }
}