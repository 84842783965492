<template>
  <div class="g-bg-color--primary-ltr">
    <div class="container g-padding-y-80--xs g-padding-y-125--sm">
      <div class="g-text-center--xs g-margin-b-100--xs">
        <p
          class="text-uppercase g-font-size-14--xs g-font-weight--700 g-color--white-opacity g-letter-spacing--2 g-margin-b-25--xs"
        >
          {{ $t("Process.Process") }}
        </p>
        <h2 class="g-font-size-32--xs g-font-size-36--md g-color--white">
          {{ $t("Process.HowitWork") }}
        </h2>
      </div>
      <ul class="list-inline row g-margin-b-100--xs">
        <!-- Process -->
        <li
          class="col-sm-3 col-xs-6 g-full-width--xs s-process-v1 g-margin-b-60--xs g-margin-b-0--md"
        >
          <div class="center-block g-text-center--xs">
            <div class="g-margin-b-30--xs">
              <span
                class="g-display-inline-block--xs g-width-100--xs g-height-100--xs g-font-size-38--xs g-color--primary g-bg-color--white g-box-shadow__dark-lightest-v4 g-padding-x-20--xs g-padding-y-20--xs g-radius--circle"
                >01</span
              >
            </div>
            <div class="g-padding-x-20--xs">
              <h3 class="g-font-size-18--xs g-color--white">
                {{ $t("Process.Detail1.Title") }}
              </h3>
              <p class="g-color--white-opacity">
                {{ $t("Process.Detail1.Remark") }}
              </p>
            </div>
          </div>
        </li>
        <!-- End Process -->

        <!-- Process -->
        <li
          class="col-sm-3 col-xs-6 g-full-width--xs s-process-v1 g-margin-b-60--xs g-margin-b-0--md"
        >
          <div class="center-block g-text-center--xs">
            <div class="g-margin-b-30--xs">
              <span
                class="g-display-inline-block--xs g-width-100--xs g-height-100--xs g-font-size-38--xs g-color--primary g-bg-color--white g-box-shadow__dark-lightest-v4 g-padding-x-20--xs g-padding-y-20--xs g-radius--circle"
                >02</span
              >
            </div>
            <div class="g-padding-x-20--xs">
              <h3 class="g-font-size-18--xs g-color--white">
                {{ $t("Process.Detail2.Title") }}
              </h3>
              <p class="g-color--white-opacity">
                {{ $t("Process.Detail2.Remark") }}
              </p>
            </div>
          </div>
        </li>
        <!-- End Process -->

        <!-- Process -->
        <li
          class="col-sm-3 col-xs-6 g-full-width--xs s-process-v1 g-margin-b-60--xs g-margin-b-0--sm"
        >
          <div class="center-block g-text-center--xs">
            <div class="g-margin-b-30--xs">
              <span
                class="g-display-inline-block--xs g-width-100--xs g-height-100--xs g-font-size-38--xs g-color--primary g-bg-color--white g-box-shadow__dark-lightest-v4 g-padding-x-20--xs g-padding-y-20--xs g-radius--circle"
                >03</span
              >
            </div>
            <div class="g-padding-x-20--xs">
              <h3 class="g-font-size-18--xs g-color--white">
                {{ $t("Process.Detail3.Title") }}
              </h3>
              <p class="g-color--white-opacity">
                {{ $t("Process.Detail3.Remark") }}
              </p>
            </div>
          </div>
        </li>
        <!-- End Process -->

        <!-- Process -->
        <li class="col-sm-3 col-xs-6 g-full-width--xs s-process-v1">
          <div class="center-block g-text-center--xs">
            <div class="g-margin-b-30--xs">
              <span
                class="g-display-inline-block--xs g-width-100--xs g-height-100--xs g-font-size-38--xs g-color--primary g-bg-color--white g-box-shadow__dark-lightest-v4 g-padding-x-20--xs g-padding-y-20--xs g-radius--circle"
                >04</span
              >
            </div>
            <div class="g-padding-x-20--xs">
              <h3 class="g-font-size-18--xs g-color--white">
                {{ $t("Process.Detail4.Title") }}
              </h3>
              <p class="g-color--white-opacity">
                {{ $t("Process.Detail4.Remark") }}
              </p>
            </div>
          </div>
        </li>
        <!-- End Process -->
      </ul>

      <!-- <div class="g-text-center--xs">
        <a
          href="#js__scroll-to-appointment"
          class="text-uppercase s-btn s-btn--md s-btn--white-bg g-radius--50 g-padding-x-70--xs"
          >Hire Us</a
        >
      </div> -->
    </div>
  </div>
</template>
