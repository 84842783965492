<template>
  <div class="container g-padding-y-80--xs g-padding-y-125--xsm">
    <div class="g-text-center--xs g-margin-b-100--xs">
      <p
        class="text-uppercase g-font-size-14--xs g-font-weight--700 g-color--primary g-letter-spacing--2 g-margin-b-25--xs"
      >
        {{ $t("Clients.Client") }}
      </p>
      <h2 class="g-font-size-32--xs g-font-size-36--md">
        {{ $t("Clients.ClientMessage") }}
      </h2>
    </div>
    <div class="row g-margin-b-30--xs">
      <div class="col-sm-12">
        <!-- Portfolio Gallery -->
        <div
          id="js__grid-portfolio-gallery"
          class="s-portfolio__paginations-v1 cbp"
        >
          <!-- Item -->
          <div class="s-portfolio__item cbp-item motion graphic">
            <div class="s-portfolio__img-effect">
              <img src="img/clients/01-dark.jpeg" alt="Portfolio Image" />
            </div>
            <div class="s-portfolio__caption-hover--cc">
              <ul class="list-inline g-ul-li-lr-5--xs g-margin-b-0--xs">
                <li>
                  <a
                    href="img/clients/01-dark.jpeg"
                    class="cbp-lightbox s-icon s-icon--sm s-icon--white-bg g-radius--circle"
                    data-title="Noventech Çevre Teknolojileri"
                  >
                    <i class="ti-fullscreen"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.noventech.com.tr/tr"
                    class="s-icon s-icon--sm s-icon s-icon--white-bg g-radius--circle"
                  >
                    <i class="ti-link"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <!-- Item -->
          <!-- <div class="s-portfolio__item cbp-item logos graphic">
            <div class="s-portfolio__img-effect">
              <img src="img/970x647/09.jpg" alt="Portfolio Image" />
            </div>
            <div class="s-portfolio__caption-hover--cc">
              <div class="g-margin-b-25--xs">
                <h4 class="g-font-size-18--xs g-color--white g-margin-b-5--xs">
                  Portfolio Item
                </h4>
                <p class="g-color--white-opacity">by KeenThemes Inc.</p>
              </div>
              <ul class="list-inline g-ul-li-lr-5--xs g-margin-b-0--xs">
                <li>
                  <a
                    href="img/970x647/09.jpg"
                    class="cbp-lightbox s-icon s-icon--sm s-icon--white-bg g-radius--circle"
                    data-title="Portfolio Item <br/> by KeenThemes Inc."
                  >
                    <i class="ti-fullscreen"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://themeforest.net/item/metronic-responsive-admin-dashboard-template/4021469?ref=keenthemes"
                    class="s-icon s-icon--sm s-icon s-icon--white-bg g-radius--circle"
                  >
                    <i class="ti-link"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div> -->
          <!-- Item -->
        </div>
        <!-- End Portfolio Gallery -->
      </div>
    </div>
  </div>
</template>
