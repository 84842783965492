<template>
  <div
    class="js__parallax-window"
    style="background: url(img/1920x1080/forest.jpg) 50% 0 no-repeat fixed"
  >
    <div
      class="container g-text-center--xs g-padding-y-80--xs g-padding-y-125--sm"
    >
      <div class="g-margin-b-80--xs">
        <h2
          class="g-font-size-40--xs g-font-size-50--sm g-font-size-60--md g-color--white"
        >
          {{ $t("Paralax.Message") }}
        </h2>
      </div>
      <!-- <a
        href="http://keenthemes.com/"
        class="text-uppercase s-btn s-btn--md s-btn--white-brd g-radius--50"
        >Learn More</a
      > -->
    </div>
  </div>
</template>
