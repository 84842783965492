<template>
  <div
    class="js__parallax-window"
    style="background: url(img/1920x1080/04.jpg) 50% 0 no-repeat fixed"
  >
    <div
      class="container g-text-center--xs g-padding-y-80--xs g-padding-y-125--sm"
    >
      <p
        class="text-uppercase g-font-size-14--xs g-font-weight--700 g-color--white-opacity g-letter-spacing--2 g-margin-b-50--xs"
      >
        {{ $t("Testimonials.Title") }}
      </p>
      <div class="s-swiper js__swiper-testimonials">
        <!-- Swiper Wrapper -->
        <div class="swiper-wrapper g-margin-b-50--xs">
          <!-- <div class="swiper-slide g-padding-x-130--sm g-padding-x-150--lg">
            <div class="g-padding-x-20--xs g-padding-x-50--lg">
              <div class="g-margin-b-40--xs">
                <p class="g-font-size-22--xs g-font-size-28--sm g-color--white">
                  <i>" {{ $t("Testimonials.Detail1.Remark") }} "</i>
                </p>
              </div>
              <div
                class="center-block g-hor-divider__solid--white-opacity-lightest g-width-100--xs g-margin-b-30--xs"
              ></div>
              <h4
                class="g-font-size-15--xs g-font-size-18--sm g-color--white-opacity-light g-margin-b-5--xs"
                v-html="$t('Testimonials.Detail1.Person')"
              ></h4>
            </div>
          </div> -->
          <div class="swiper-slide g-padding-x-130--sm g-padding-x-150--lg">
            <div class="g-padding-x-20--xs g-padding-x-50--lg">
              <div class="g-margin-b-40--xs">
                <p class="g-font-size-22--xs g-font-size-28--sm g-color--white">
                  <i>" {{ $t("Testimonials.Detail2.Remark") }} "</i>
                </p>
              </div>
              <div
                class="center-block g-hor-divider__solid--white-opacity-lightest g-width-100--xs g-margin-b-30--xs"
              ></div>
              <h4
                class="g-font-size-15--xs g-font-size-18--sm g-color--white-opacity-light g-margin-b-5--xs"
                v-html="$t('Testimonials.Detail2.Person')"
              ></h4>
            </div>
          </div>
          <!-- <div class="swiper-slide g-padding-x-130--sm g-padding-x-150--lg">
            <div class="g-padding-x-20--xs g-padding-x-50--lg">
              <div class="g-margin-b-40--xs">
                <p class="g-font-size-22--xs g-font-size-28--sm g-color--white">
                  <i
                    >" I have purchased many great templates over the years but
                    this product and this company have taken it to the next
                    level. Exceptional customizability. "</i
                  >
                </p>
              </div>
              <div
                class="center-block g-hor-divider__solid--white-opacity-lightest g-width-100--xs g-margin-b-30--xs"
              ></div>
              <h4
                class="g-font-size-15--xs g-font-size-18--sm g-color--white-opacity-light g-margin-b-5--xs"
              >
                Jake Richardson / Google
              </h4>
            </div>
          </div>
          <div class="swiper-slide g-padding-x-130--sm g-padding-x-150--lg">
            <div class="g-padding-x-20--xs g-padding-x-50--lg">
              <div class="g-margin-b-40--xs">
                <p class="g-font-size-22--xs g-font-size-28--sm g-color--white">
                  <i
                    >" I have purchased many great templates over the years but
                    this product and this company have taken it to the next
                    level. Exceptional customizability. "</i
                  >
                </p>
              </div>
              <div
                class="center-block g-hor-divider__solid--white-opacity-lightest g-width-100--xs g-margin-b-30--xs"
              ></div>
              <h4
                class="g-font-size-15--xs g-font-size-18--sm g-color--white-opacity-light g-margin-b-5--xs"
              >
                Jake Richardson / Google
              </h4>
            </div>
          </div> -->
        </div>
        <!-- End Swipper Wrapper -->

        <!-- Arrows -->
        <div
          class="g-font-size-22--xs g-color--white-opacity js__swiper-fraction"
        ></div>
        <a
          href="javascript:void(0);"
          class="g-display-none--xs g-display-inline-block--sm s-swiper__arrow-v1--right s-icon s-icon--md s-icon--white-brd g-radius--circle ti-angle-right js__swiper-btn--next"
        ></a>
        <a
          href="javascript:void(0);"
          class="g-display-none--xs g-display-inline-block--sm s-swiper__arrow-v1--left s-icon s-icon--md s-icon--white-brd g-radius--circle ti-angle-left js__swiper-btn--prev"
        ></a>
        <!-- End Arrows -->
      </div>
    </div>
  </div>
</template>
