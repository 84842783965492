<template>
  <header
    class="navbar-fixed-top s-header js__header-sticky js__header-overlay"
  >
    <!-- Navbar -->
    <div class="s-header__navbar">
      <div class="s-header__container">
        <div class="s-header__navbar-row">
          <div class="s-header__navbar-row-col">
            <!-- Logo -->
            <div class="s-header__logo">
              <a href="index.html" class="s-header__logo-link">
                <img
                  class="s-header__logo-img s-header__logo-img-default"
                  src="img/logo.png"
                  alt="Benafri Logo"
                />
                <img
                  class="s-header__logo-img s-header__logo-img-shrink"
                  src="img/logo-dark.png"
                  alt="Benafri Logo"
                />
              </a>
            </div>
            <!-- End Logo -->
          </div>
          <div class="s-header__navbar-row-col">
            <!-- Trigger -->
            <a href="javascript:void(0);" class="s-header__trigger js__trigger">
              <span class="s-header__trigger-icon"></span>
              <svg
                x="0rem"
                y="0rem"
                width="3.125rem"
                height="3.125rem"
                viewbox="0 0 54 54"
              >
                <circle
                  fill="transparent"
                  stroke="#fff"
                  stroke-width="1"
                  cx="27"
                  cy="27"
                  r="25"
                  stroke-dasharray="157 157"
                  stroke-dashoffset="157"
                ></circle>
              </svg>
            </a>
            <!-- End Trigger -->
          </div>
        </div>
      </div>
    </div>
    <!-- End Navbar -->

    <!-- Overlay -->
    <div class="s-header-bg-overlay js__bg-overlay">
      <!-- Nav -->
      <nav class="s-header__nav js__scrollbar">
        <div class="container-fluid">
          <!-- Menu List -->
          <ul class="list-unstyled s-header__nav-menu">
            <!-- <li class="s-header__nav-menu-item">
              <a
                class="s-header__nav-menu-link s-header__nav-menu-link-divider -is-active"
                href="index.html"
                >Corporate</a
              >
            </li>
            <li class="s-header__nav-menu-item">
              <a
                class="s-header__nav-menu-link s-header__nav-menu-link-divider"
                href="index_app_landing.html"
                >App Landing</a
              >
            </li>
            <li class="s-header__nav-menu-item">
              <a
                class="s-header__nav-menu-link s-header__nav-menu-link-divider"
                href="index_portfolio.html"
                >Portfolio</a
              >
            </li>
            <li class="s-header__nav-menu-item">
              <a
                class="s-header__nav-menu-link s-header__nav-menu-link-divider"
                href="index_events.html"
                >Events</a
              >
            </li>
            <li class="s-header__nav-menu-item">
              <a
                class="s-header__nav-menu-link s-header__nav-menu-link-divider"
                href="index_lawyer.html"
                >Lawyer</a
              >
            </li>
            <li class="s-header__nav-menu-item">
              <a
                class="s-header__nav-menu-link s-header__nav-menu-link-divider"
                href="index_clinic.html"
                >Clinic</a
              >
            </li> -->
            <li class="s-header__nav-menu-item">
              <a
                class="s-header__nav-menu-link s-header__nav-menu-link-divider"
                href="http://app.benafri.com/"
                >{{ $t("NavMenu.AppLogin") }}</a
              >
            </li>
          </ul>
          <!-- End Menu List -->

          <!-- Menu List -->
          <ul class="list-unstyled s-header__nav-menu">
            <li class="s-header__nav-menu-item">
              <router-link to="/">
                <a
                  class="s-header__nav-menu-link s-header__nav-menu-link-divider"
                  >{{ $t("NavMenu.Home") }}</a
                >
              </router-link>
            </li>
            <!-- <li class="s-header__nav-menu-item">
              <a
                class="s-header__nav-menu-link s-header__nav-menu-link-divider"
                href="team.html"
                >{{ $t("NavMenu.About") }}</a
              >
            </li> -->
            <li class="s-header__nav-menu-item">
              <router-link to="/team">
                <a
                  class="s-header__nav-menu-link s-header__nav-menu-link-divider"
                  >{{ $t("NavMenu.Team") }}</a
                >
              </router-link>
            </li>
            <li class="s-header__nav-menu-item">
              <router-link to="/events">
                <a
                  class="s-header__nav-menu-link s-header__nav-menu-link-divider"
                  >{{ $t("NavMenu.Events") }}</a
                >
              </router-link>
            </li>
            <!-- <li class="s-header__nav-menu-item">
              <a
                class="s-header__nav-menu-link s-header__nav-menu-link-divider"
                href="faq.html"
                >{{ $t("NavMenu.Faq") }}</a
              >
            </li> -->
            <li class="s-header__nav-menu-item">
              <router-link to="/contact">
                <a
                  class="s-header__nav-menu-link s-header__nav-menu-link-divider"
                  >{{ $t("NavMenu.Contact") }}</a
                >
              </router-link>
            </li>
          </ul>
          <!-- End Menu List -->
        </div>
      </nav>
      <!-- End Nav -->

      <!-- Action -->
      <ul class="list-inline s-header__action s-header__action--lb">
        <li class="s-header__action-item">
          <a class="s-header__action-link -is-active" href="#">TR</a>
        </li>
        <li class="s-header__action-item">
          <a class="s-header__action-link" href="#">EN</a>
        </li>
      </ul>
      <!-- End Action -->

      <!-- Action -->
      <ul class="list-inline s-header__action s-header__action--rb">
        <li class="s-header__action-item">
          <a class="s-header__action-link" href="#">
            <i class="g-padding-r-5--xs ti-facebook"></i>
            <span class="g-display-none--xs g-display-inline-block--sm"
              >Facebook</span
            >
          </a>
        </li>
        <li class="s-header__action-item">
          <a class="s-header__action-link" href="#">
            <i class="g-padding-r-5--xs ti-linkedin"></i>
            <span class="g-display-none--xs g-display-inline-block--sm"
              >Linkedin</span
            >
          </a>
        </li>
        <li class="s-header__action-item">
          <a class="s-header__action-link" href="#">
            <i class="g-padding-r-5--xs ti-instagram"></i>
            <span class="g-display-none--xs g-display-inline-block--sm"
              >Instagram</span
            >
          </a>
        </li>
      </ul>
      <!-- End Action -->
    </div>
    <!-- End Overlay -->
  </header>
</template>
<script></script>
<style></style>
