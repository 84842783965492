<template>
  <div
    id="js__scroll-to-section"
    class="container g-padding-y-80--xs g-padding-y-125--sm"
  >
    <div class="g-text-center--xs g-margin-b-100--xs">
      <p
        class="text-uppercase g-font-size-14--xs g-font-weight--700 g-color--primary g-letter-spacing--2 g-margin-b-25--xs"
      >
        {{ $t("Feature.Welcome") }}
      </p>
      <h2 class="g-font-size-32--xs g-font-size-36--md">
        {{ $t("Feature.Message") }}
      </h2>
    </div>
    <div class="row g-margin-b-60--xs g-margin-b-70--md">
      <div class="col-sm-4 g-margin-b-60--xs g-margin-b-0--md">
        <div class="clearfix">
          <div class="g-media g-width-30--xs">
            <div
              class="wow fadeInDown"
              data-wow-duration=".3"
              data-wow-delay=".1s"
            >
              <i class="g-font-size-28--xs g-color--primary ti-desktop"></i>
            </div>
          </div>
          <div class="g-media__body g-padding-x-20--xs">
            <h3 class="g-font-size-18--xs">
              {{ $t("Feature.Detail1.Title") }}
            </h3>
            <p class="g-margin-b-0--xs">
              {{ $t("Feature.Detail1.Remark") }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-4 g-margin-b-60--xs g-margin-b-0--md">
        <div class="clearfix">
          <div class="g-media g-width-30--xs">
            <div
              class="wow fadeInDown"
              data-wow-duration=".3"
              data-wow-delay=".2s"
            >
              <i class="g-font-size-28--xs g-color--primary ti-settings"></i>
            </div>
          </div>
          <div class="g-media__body g-padding-x-20--xs">
            <h3 class="g-font-size-18--xs">
              {{ $t("Feature.Detail2.Title") }}
            </h3>
            <p class="g-margin-b-0--xs">
              {{ $t("Feature.Detail2.Remark") }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="clearfix">
          <div class="g-media g-width-30--xs">
            <div
              class="wow fadeInDown"
              data-wow-duration=".3"
              data-wow-delay=".3s"
            >
              <i class="g-font-size-28--xs g-color--primary ti-ruler-alt-2"></i>
            </div>
          </div>
          <div class="g-media__body g-padding-x-20--xs">
            <h3 class="g-font-size-18--xs">
              {{ $t("Feature.Detail3.Title") }}
            </h3>
            <p class="g-margin-b-0--xs">
              {{ $t("Feature.Detail3.Remark") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- // end row  -->
    <div class="row">
      <div class="col-sm-4 g-margin-b-60--xs g-margin-b-0--md">
        <div class="clearfix">
          <div class="g-media g-width-30--xs">
            <div
              class="wow fadeInDown"
              data-wow-duration=".3"
              data-wow-delay=".4s"
            >
              <i class="g-font-size-28--xs g-color--primary ti-package"></i>
            </div>
          </div>
          <div class="g-media__body g-padding-x-20--xs">
            <h3 class="g-font-size-18--xs">
              {{ $t("Feature.Detail4.Title") }}
            </h3>
            <p class="g-margin-b-0--xs">
              {{ $t("Feature.Detail4.Remark") }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-4 g-margin-b-60--xs g-margin-b-0--md">
        <div class="clearfix">
          <div class="g-media g-width-30--xs">
            <div
              class="wow fadeInDown"
              data-wow-duration=".3"
              data-wow-delay=".5s"
            >
              <i class="g-font-size-28--xs g-color--primary ti-star"></i>
            </div>
          </div>
          <div class="g-media__body g-padding-x-20--xs">
            <h3 class="g-font-size-18--xs">
              {{ $t("Feature.Detail5.Title") }}
            </h3>
            <p class="g-margin-b-0--xs">
              {{ $t("Feature.Detail5.Remark") }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-sm-4">
        <div class="clearfix">
          <div class="g-media g-width-30--xs">
            <div
              class="wow fadeInDown"
              data-wow-duration=".3"
              data-wow-delay=".6s"
            >
              <i class="g-font-size-28--xs g-color--primary ti-panel"></i>
            </div>
          </div>
          <div class="g-media__body g-padding-x-20--xs">
            <h3 class="g-font-size-18--xs">
              {{ $t("Feature.Detail6.Title") }}
            </h3>
            <p class="g-margin-b-0--xs">
              {{ $t("Feature.Detail6.Remark") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- // end row  -->
  </div>
</template>
