<template>
  <footer class="g-bg-color--dark">
    <!-- Links -->
    <div class="g-hor-divider__dashed--white-opacity-lightest">
      <div class="container g-padding-y-80--xs">
        <div class="row">
          <div class="col-sm-2 g-margin-b-20--xs g-margin-b-0--md">
            <ul class="list-unstyled g-ul-li-tb-5--xs g-margin-b-0--xs">
              <li>
                <router-link to="/">
                  <a class="g-font-size-15--xs g-color--white-opacity">{{
                    $t("Footer.Nav.Home")
                  }}</a></router-link
                >
              </li>
              <li>
                <router-link to="/team">
                  <a class="g-font-size-15--xs g-color--white-opacity">{{
                    $t("Footer.Nav.Team")
                  }}</a>
                </router-link>
              </li>
              <li>
                <router-link to="/events">
                  <a class="g-font-size-15--xs g-color--white-opacity">{{
                    $t("Footer.Nav.Events")
                  }}</a></router-link
                >
              </li>
              <li>
                <router-link to="/contact">
                  <a class="g-font-size-15--xs g-color--white-opacity">{{
                    $t("Footer.Nav.Contact")
                  }}</a></router-link
                >
              </li>
            </ul>
          </div>
          <div class="col-sm-2 g-margin-b-20--xs g-margin-b-0--md">
            <ul class="list-unstyled g-ul-li-tb-5--xs g-margin-b-0--xs">
              <li>
                <a class="g-font-size-15--xs g-color--white-opacity" href="/">{{
                  $t("Footer.SocialMedia.Facebook")
                }}</a>
              </li>
              <li>
                <a class="g-font-size-15--xs g-color--white-opacity" href="/">{{
                  $t("Footer.SocialMedia.Instagram")
                }}</a>
              </li>
              <li>
                <a class="g-font-size-15--xs g-color--white-opacity" href="/">{{
                  $t("Footer.SocialMedia.Linkedin")
                }}</a>
              </li>
              <!-- <li>
                <a
                  class="g-font-size-15--xs g-color--white-opacity"
                  href="http://themeforest.net/item/metronic-responsive-admin-dashboard-template/4021469?ref=keenthemes"
                  >YouTube</a
                >
              </li> -->
            </ul>
          </div>
          <!-- <div class="col-sm-2 g-margin-b-40--xs g-margin-b-0--md">
            <ul class="list-unstyled g-ul-li-tb-5--xs g-margin-b-0--xs">
              <li>
                <a
                  class="g-font-size-15--xs g-color--white-opacity"
                  href="http://themeforest.net/item/metronic-responsive-admin-dashboard-template/4021469?ref=keenthemes"
                  >Subscribe to Our Newsletter</a
                >
              </li>
              <li>
                <a
                  class="g-font-size-15--xs g-color--white-opacity"
                  href="http://themeforest.net/item/metronic-responsive-admin-dashboard-template/4021469?ref=keenthemes"
                  >Privacy Policy</a
                >
              </li>
              <li>
                <a
                  class="g-font-size-15--xs g-color--white-opacity"
                  href="http://themeforest.net/item/metronic-responsive-admin-dashboard-template/4021469?ref=keenthemes"
                  >Terms &amp; Conditions</a
                >
              </li>
            </ul>
          </div> -->
          <div
            class="col-md-4 col-md-offset-2 col-sm-5 col-sm-offset-1 s-footer__logo g-padding-y-50--xs g-padding-y-0--md"
          >
            <h3 class="g-font-size-18--xs g-color--white">
              {{ $t("Footer.CompanyInfo.Title") }}
            </h3>
            <p class="g-color--white-opacity">
              {{ $t("Footer.CompanyInfo.Remark") }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- End Links -->

    <!-- Copyright -->
    <div class="container g-padding-y-50--xs">
      <div class="row">
        <div class="col-xs-6">
          <router-link to="/">
            <a>
              <img
                class="g-width-100--xs g-height-auto--xs"
                src="img/logo.png"
                alt="Benafri Logo"
              /> </a
          ></router-link>
        </div>
        <div class="col-xs-6 g-text-right--xs">
          <p
            class="g-font-size-14--xs g-margin-b-0--xs g-color--white-opacity-light"
          >
            {{ $t("Footer.CopyRight") }}
            <!-- <a href="http://keenthemes.com/preview/Megakit/">Megakit</a> Theme
            Powered by: <a href="http://www.keenthemes.com/">KeenThemes.com</a> -->
          </p>
        </div>
      </div>
    </div>
    <!-- End Copyright -->
  </footer>
</template>
<script setup></script>
<style></style>
